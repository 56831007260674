import React from "react";
import { SvgIcon } from "@mui/material";

function ResourceMetrixIcon(props) {
  return (
    <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M14.048 3.202a1.954 1.954 0 00-1.952 1.952c0 .518.204.99.535 1.34L10.995 8.96a1.948 1.948 0 00-1.82.445l-1.22-1.22a1.954 1.954 0 00-1.733-2.848A1.954 1.954 0 004.455 8.12L3.067 9.245a1.94 1.94 0 00-1.115-.35A1.954 1.954 0 000 10.845c0 1.076.876 1.952 1.952 1.952a1.954 1.954 0 001.787-2.736L5.15 8.92a1.94 1.94 0 002.082.039l1.375 1.376a1.954 1.954 0 001.883 2.464 1.954 1.954 0 001.952-1.952c0-.52-.205-.993-.538-1.343l1.635-2.465A1.954 1.954 0 0016 5.154a1.954 1.954 0 00-1.95-1.952zM1.952 11.74a.895.895 0 010-1.788.895.895 0 010 1.788zm4.27-3.557a.895.895 0 010-1.789.895.895 0 010 1.789zm4.269 3.557a.895.895 0 010-1.788.895.895 0 010 1.788zm3.557-5.692a.895.895 0 010-1.788.895.895 0 010 1.788z"
      ></path>
    </svg>
    </SvgIcon >
  );
}

export default ResourceMetrixIcon;