const ROLES = {
  ALL: [
    "c2c_super_admin",
    "c2c_viewer",
    "c2c_client_viewer",
    "c2c_client_admin",
    "c2c_est_viewer",
    "c2c_est_admin",
    "c2c_pricing_viewer",
    "c2c_pricing_admin",
    "c2c_sow_viewer",
    "c2c_sow_admin",
    "c2c_milestone_viewer",
    "c2c_milestone_admin",
    "c2c_po_viewer",
    "c2c_po_admin",
    "c2c_allocation_viewer",
    "c2c_allocation_admin",
    "c2c_invoice_admin",
    "c2c_invoice_viewer",
  ],
  SUPER_ADMIN: "c2c_super_admin",
  VIEWER: "c2c_viewer",
  CLIENT_VIEW: "c2c_client_viewer",
  CLIENT_ADMIN: "c2c_client_admin",
  EST_VIEW: "c2c_est_viewer",
  EST_ADMIN: "c2c_est_admin",
  PRICING_VIEW: "c2c_pricing_viewer",
  PRICING_ADMIN: "c2c_pricing_admin",
  SOW_VIEW: "c2c_sow_viewer",
  SOW_ADMIN: "c2c_sow_admin",
  MILESTONE_VIEW: "c2c_milestone_viewer",
  MILESTONE_ADMIN: "c2c_milestone_admin",
  PO_VIEW: "c2c_po_viewer",
  PO_ADMIN: "c2c_po_admin",
  ALLOCATION_VIEW: "c2c_allocation_viewer",
  ALLOCATION_ADMIN: "c2c_allocation_admin",
  RESOURCE_VIEW: "c2c_resource_viewer",
  TIMESHEET_VIEW: "c2c_timesheet_manager",
  TIMESHEET_ADMIN: "c2c_timesheet_admin",
  DASHBOARD_VIEW: "c2c_dashboard_viewer",
  INVOICE_VIEW: "c2c_invoice_viewer",
  INVOICE_ADMIN: "c2c_invoice_admin",
};

export default ROLES;
