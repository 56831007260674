import React from "react";
import { SvgIcon } from "@mui/material";

function DashboardIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                width={16}
                height={17}
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M15.5972 2.43757L15.4557 5.37461C15.4378 5.73344 15.1414 6.01501 14.7821 6.01456H14.7349C14.3761 5.9966 14.0945 5.7002 14.095 5.34093L14.1421 4.34395L10.2216 8.91792C10.0954 9.06153 9.91423 9.14479 9.7231 9.14696C9.53109 9.16153 9.34356 9.08402 9.21788 8.93813L6.00465 5.53628L1.48457 8.97181C1.36613 9.06066 1.22171 9.10801 1.07366 9.10654C0.86517 9.10604 0.668664 9.00903 0.541487 8.84382C0.315282 8.54971 0.369494 8.12806 0.662741 7.90074L5.68131 4.05429C5.95638 3.84285 6.34706 3.87492 6.58397 4.12839L9.69616 7.42919L13.2125 3.32677L12.1145 3.49518C11.7533 3.53946 11.4223 3.28893 11.3668 2.92933C11.3106 2.56156 11.5631 2.21786 11.9309 2.16165C11.9315 2.16156 11.932 2.16147 11.9326 2.16138L14.8427 1.72352C15.0428 1.69389 15.2456 1.75572 15.3951 1.89193C15.5416 2.03384 15.6159 2.23448 15.5972 2.43757ZM14.7349 13.9365H14.0613V9.1739C14.0613 8.80186 13.7597 8.50027 13.3877 8.50027C13.0156 8.50027 12.714 8.80186 12.714 9.1739V13.9365H10.3967V11.8684C10.3967 11.4964 10.0951 11.1948 9.7231 11.1948C9.35106 11.1948 9.04947 11.4964 9.04947 11.8684V13.9365H6.76585V9.1739C6.76585 8.80186 6.46426 8.50027 6.09222 8.50027C5.72018 8.50027 5.41859 8.80186 5.41859 9.1739V13.9365H3.13497V11.1948C3.13497 10.8228 2.83338 10.5212 2.46134 10.5212C2.0893 10.5212 1.78771 10.8228 1.78771 11.1948V13.9365H1.07366C0.70162 13.9365 0.400024 14.2381 0.400024 14.6101C0.400024 14.9822 0.70162 15.2837 1.07366 15.2837H14.7349C15.107 15.2837 15.4086 14.9822 15.4086 14.6101C15.4086 14.2381 15.107 13.9365 14.7349 13.9365Z"
                    fill="#8E97A7"
                />
            </svg>

        </SvgIcon >
    );
}

export default DashboardIcon;