const en = {
  address: "Address",
  addEstimation: "Add Estimation",
  addResources: "Add Resources",
  resourceRole: "Resource Role",
  region: "Region",
  skill: "Skill",
  startDate: "Start Date",
  endDate: "End Date",
  payRate: "Bill Rate",
  companyRate: "Company Rate",
  marketRate: "Market Rate",
  estimationHrs: "Estimation Hrs",
  estimation: "Estimation",
  versionNumber: "Version Number",
  donthaveaccount: "Don’t have an account?",
  getstarted: "Get started",
  welcomeback: "Hi, Welcome Back",
  contractType: "Contract Type",
  Login: "login",
  EnterYouremailIdandPassword: "Enter your email Id and Password",
  emailId: `Email Id`,
  estimations: "Estimations",
  effortEstimations: "Effort Estimation​",
  password: "Password",
  confirmpassword: "Confirm Password",
  changepassword: "Change Password",
  currentpassword: "Current Password",
  clientName: "Client Name",
  contractName: "Contract Name",
  milestoneName: "Milestone Name",
  milestones: "Milestones",
  milestoneDate: "Milestone Date",
  deliverables: "Deliverables",
  milestoneAmount: "Milestone Amount",
  newpassword: "New Password",
  noDataFound: "No Data Found",
  confirmnewpassword: "Confirm New Password",
  confirmDelete: "Confirm Delete",
  rememberme: "Remember me",
  forgotpassword: "Forgot Password?",
  login: "Login",
  or: "or",
  and: "and",
  register: "Register",
  alreadyhaveaccount: "Already have an account?",
  agreement: "By registering, I agree to Matchpoint Solutions",
  termservice: "Terms of Service",
  privacypolicy: "Privacy Policy",
  firstname: "First Name",
  lastname: "Last Name",
  mobile: "Mobile Number",
  email: "Email",
  emailaddress: "Email Address",
  dashboard: "Dashboard",
  mainMenu: "Main Menu",
  user: "User",
  users: "Users",
  role: "Role",
  roles: "Roles",
  profile: "Profile",
  logout: "Logout",
  list: "List",
  add: "ADD",
  client: "Client",
  clientManagement: "Client Management",
  ResourceManagement: "Resource Management",
  Resources: "Resources",
  ResourcesDetail: "Resources Detail",
  ClientName: "ClientName",
  EffortEstimation: "Effort Estimation",
  Pricing: "Pricing",
  SOWContract: "SOWContract",
  Contracts: "Contracts",
  Milestones: "Milestones",
  PurchaseOrders: "Purchase Orders",
  Allocations: "Allocations",
  Timesheets: "Timesheets",
  invoicing: "Invoicing",
  new: "New",
  save: "Save",
  edit: "Edit",
  delete: "Delete",
  confirm: "Confirm",
  confirmAmount: "Confirm Amount",
  submit: "Submit",
  cancel: "Cancel",
  upload: "Upload",
  photo: "Photo",
  theme: "Theme",
  language: "Language",
  settings: "Settings",
  themepreference: "Theme preference",
  languagepreference: "Language preference",
  allowed: "Allowed",
  createrole: "Create a new role",
  rolename: "Role Name",
  data: "We are currently working on this feature and improving the expereince",
  text1: "We're",
  text2: "Launching Soon!",

  pageNotFound: "Opps! Page Not Found",
  helpCenter1: "Try going back or see our",
  helpCenter2: " Help Center",
  helpCenter3: " for more information",

  PossibleReasons: "Possible  Reasons",
  URL1: "The URL entered may be wrong.",
  URL2: "The URL may be broken are never existed.",
  roledesc: "Role description",
  createuser: "Create a new user",
  overview: "Overview",
  permissions: "Permissions",
  permissiondesc: "Please select permissions",
  payment: "Payment",
  paymentMessage: {
    success: "Transaction completed successfully!",
    error: "Something wend wrong, Please try again!",
    cancel: "Transaction cancelled by user.",
  },
  status: "Status",
  search: "Search",
  RESOURCES: "Resources",
  transactionid: "Transaction Id",
  validation: {
    required: "is required",
    minlength: "must be 8 character long",
    maxlength: "should not be greater than 120 characters long",
    password:
      "Password must have atleast one Capital, one special character, one number",
    match: "must match",
    valid: "Please enter valid",
    onlyalpha: "should contains alphabets only",
    username: "Username allowed alphanumeric, . _ + - @ characters",
  },
  message: {
    create: "Created successfully!",
    save: "Saved successfully!",
    delete: "Deleted successfully!",
    change: "Changed successfully!",
    update: "Updated successfully!",
    deleteConfirm: "Are you sure you want to delete this?",
    maxsize: "max size of",
    regenerate:"Regenerated successfully!",
    markAsPaid:"Marked As Paid!",
    alreadyPaid:"Already Paid",
    emailSent:"Email Sent"
  },
  error: {
    deleteFail: "Unable to delete!",
    invalid: "Invalid response data!",
    create: "Unable to create!",
    update: "Unable to update!",
    upload: "Unable to upload!",
    download: "Unable to download!",
    fetch: "Unable to retrieve!",
    poExtractFile: "Unable to retrieve Info from File",
    Add: "Unable to Add Milestone amount exceeds contract amount",
    sowExtractFile:
      "We are unable to identify any amounts or dates in this document.",
    sowPriceAmountMismatch: "Mismatch in Price & Uploaded File Amount",
    RegenerateFail:"Regenerate Failed.",
    MarkAsPaidFailed:"Mark as Paid Failed",
    sendEmailFailed:"Email send Failed"
  },

  client: {
    addClientNoDataMsg: "Add Client to view the details",
  },
  ClientPreviewScreen: {
    CLIENT_DETAILS: "Client Details",
    CLIENT_NAME: "Client Name",
    CLIENT_ADDRESS: "Client Address",
    CLIENT_CREATION_DATE: "Client Creation Date",
    CLIENT_CREATED_BY: "Client Created By",
    ORG_DETAILS: "Org Level Contract Details",
    CONTRACT_NAME: "Contract Name",
    CONTRACT_ADDRESS: "Contract Address",
    CONTRACT_TYPE: "Contract Type",
    CONTRACT_CREATION_DATE: "Contract Creation Date",
    CONTRACT_START_DATE: "Contract Start Date",
    CONTRACT_END_DATE: "Contract End Date",
    CONTRACT_VERSION: "Contract Version",
    UPLOAD_FILES: "Upload Files",
    GLOBAL_PAYMENNT_DETAILS: "Global Payment Details",
    CLIENT_PAYMENT_TERMS: "Client Payment Terms",
    CLIENT_INVOICE_PROCESS: "Client Status",
    NAME: "Name",
    EMAIL: "Email",
  },
  CONTRACTS: {
    CONTRACTS: "Contracts",
    NO_DATA_FOUND: "No Data Found",
    ADD_CONTRACT_DETAILS: "Add Contract Details",
    CONTRACT_NAME: "Contract Name",
    CLIENT_NAME: "Client Name",
    CONTRACT_ADDRESS: "Contract Address",
    CONTRACT_TYPE: "Contract Type",
    ADD_CONTRACT: "Add Contract",
    EDIT_CONTRACT: "Edit Contract",
    PAYMENT_TERMS_CLIENT: "Payment Terms Client",
    PAYMENT_TERMS_CONTRACT: "Payment Terms Contract",
    CREATE_CONTRACTS: "Create Contract",
    UPDATE_CONTRACTS: "Update Contract",
    SELECT_ESTIMATION: "Select Estimation",
    SELECT_CONTRACT_TYPE: "Select Contract Type",
    SELECT_CONTRACT: "Select Contract",
    START_DATE: "Start Date",
    END_DATE: "End Date",
    CONTRACT_DOCUMENTS: "Contract Documents",
    TOTAL_CONTRACT_AMOUNT: "Total Contract Amount",
    TOTAL_ASSIGN_AMOUNT: "Total Assigned Amount",
    REMAINING_CONTRACT_AMOUNT: "Remaining Contract Amount",
    noDataMsg: "Add Contract to view the details",
    UNIDENTIFIED_DATA:
      "Would you like to proceed and create the contract with this document?",
  },
  MILESTONES: {
    MILESTONES: "Milestones",
    ADD_MILESTONE_DETAILS: "Add Milestone Details",
    MILESTONE_NAME: "Milestone Name",
    MILESTONE_ADDRESS: "Milestone Address",
    ADD_MILESTONE: "Add Milestone",
    EDIT_MILESTONE: "Edit Milestone",
    CREATE_MILESTONES: "Create Milestone",
    UPDATE_MILESTONES: "Update Milestone",
    MILESTONE_NUMBER: "Milestone Number",
    MILESTONE_DATE: "Milestone Date",
    MILESTONE_DELIVERABLES: "Milestone Deliverables",
    MILESTONE_AMOUNT: "Milestone Amount",
    noDataMsg: "Add Milestone to view the details",
    ENTER_MILESTONE_AMOUNT: "Enter Milestone Amount",
  },

  Estimations: {
    ESTIMATION_NAME: " Estimation Name​",
    PAY_RATE_COMPANY: "Pay Rate (Company Avg)",
    PAY_RATE_MARKET: "Pay Rate (Market Avg)",
    CREATE_ESTIMATION: "Create Estimation",
    UPDATE_ESTIMATION: "Update Estimation",
    noDataMsg: "Add Estimation to view the details",
    GROSS_MARGIN: "Gross Margin(%)",
  },
  PriceEstimation: {
    PRICING: "Pricing",
    ADD_PRICING: "Add Pricing",
    EDIT_PRICING: "Edit Pricing",
    CREATE_PRICING: "Create Pricing",
    UPDATE_PRICING: "Update Pricing",
    NO_DATA_FOUND: "No Data Found",
    noDataMsg: "Add Pricing to view the details",
    ESTIMATION_NAME: "Estimation Name",
    ESTIMATION_DATE: "Estimation Date",
    PRICING_NAME: "Pricing Name",
    ESTIMATED_COST: "Estimated Cost (Market)",
    ESTIMATION_PRICE: "Estimation Price (Market)",
    TOTAL_GM_MARKET: " Total GM (Market)",
    ESTIMATED_COST_AVG: "Total Cost to company",
    ESTIMATION_PRICE_AVG: "Total Bill Amount(Customer)",
    TOTAL_COST_MARKET: "Total Cost (Market)​",
    TOTAL_PRICE_MARKET: "Total Price (Market)​",
    TOTAL_COST_COMPANY: "Total Cost to company​​",
    TOTAL_PRICE_COMPANY: "Total Bill Amount(Customer)​​​",
    TOTAL_GM_COMPANY: "Final offer Gross Margin (%)​",
    TOTAL_ESTIMATION: "Total Estimation",
    TOTAL_GM: "Gross Margin",
    GM_MARKET: "GM (Market)",
    GM_MARKET_AVG: "GM (Company Avg)",
    DISCOUNT: "Discount %",
    FINAL_OFFER_PRICE: "Final Offer Price",
    FINAL_OFFER_MARGIN: "Final Offer Gross Margin ",
    TOTAL_COST: "Total Cost (Market)",
    TOTAL_PRICE: "Total Price (Market)",
  },
  PurchaseOrder: {
    PURCHASE_ORDER: "Purchase Order",
    ASSIGN_CONTRACTS: "Assign Purchase Order",
    ADD_PURCHASE_ORDER: "Add Purchase Order ",
    ASSIGN_PURCHASE_ORDER: "Assign Purchase Order ",
    EDIT_PURCHASE_ORDER: "Edit Purchase Order",
    noDataMsg: "Add Purchase Order to view the details",
    CONTRACT_NAME: "Contract Name",
    PO_NAME: "PO Name",
    TOTAL_PO_AMOUNT: "Total PO Amount",
    PO_AVAILABLE_AMOUNT: "PO Available Amount",
    ASSIGN_AMOUNT: "Assign Amount*",
    PO_AMOUNT: "PO Amount",
    AMOUNT_UTILIZED: "Amount Utilized(%)*",
    START_DATE: "Order Date",
    END_DATE: "Print Date",
    PO_START_DATE: "PO Order Date",
    PO_END_DATE: "PO Print Date",
    PO_DOCUMENT: "PO Document",
    CREATE_PURCHASE_ORDER: "Create Purchase Order",
    UPDATE_PURCHASE_ORDER: "Update Purchase Order",
    ACCOUNT_NUMBER: "Purchase Order",
    contractAmountError: "Contract amount can't be more than PO Amount",
    poAmountError: "Amount exceeded available PO",
  },
  Invoice:{
    NOINVOICEFOUND:"No invoices have been generated yet. We'll create invoices automatically based on Milestones and Employee allocations."
  },
  Allocation: {
    ADD: "Add Allocations",
    UPDATE:"Update Allocation",
    ALLOCATIONS: "Allocations",
    ADD_ALLOCATION: "Add Allocation",
    NAME: "Allocation Name",
    CREATE: "Create Allocation",
    COUNT:"Allocation Count",
    START_DATE:"Start Date",
    END_DATE:"End Date",
    TOTAL_BILL_HRS:"Total Billable Hrs",
    TOTAL_CNT_HRS:"Total Contract Hrs",
  },
  EstimationCalendar: {
    MONTHLY: "Monthly",
    DAILY: "Daily",
    WEEKLY: "Weekly",
    TOTAL_HOURS: "Total Weekly Hours",
    TOTAL_ESTIMATED_HOURS: "Total Estimated Hours",
    TOTAL_AVAIL_HOURS: "Total Available Hours",
    SPLIT_HOURS: "Split",
    HOURS_NOTIFICATION:
      "Week hours are automatically adjusted in the daily hours. Manually enter the hours to change",
    HOURS: "Hours",
  },
  VIEW_TYPE: {
    MONTHLY: "monthly",
    DAILY: "daily",
    WEEKLY: "weekly",
  },
  MONTHLABELS: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  hours: "Hours",
};

export default en;
